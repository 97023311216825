@font-face {
  font-family: "Poppings Regular";
  src: url("./Assets/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppings Semibold";
  src: url("./Assets/Poppins/Poppins-SemiBold.ttf");
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  font-family: "Montserrat", sans-serif;
  background-color: #f0f9fb;
}