.App-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: transparent;
  pointer-events: none;
  z-index: 0;
}

.longShape {
  position: absolute;
  width: 400px;
  height: 77px;
  left: calc(50% - 10px - 971.5px);
  top: calc(50% - 77px / 2 - 210px);
  background: #f0f9fb;
  box-shadow: 18px 18px 30px #d1d9e6, -18px -18px 30px #ffffff;
  border-radius: 110px;
}

.roundShape {
  position: absolute;
  background: #f0f9fb;
  box-shadow: 18px 18px 30px #d1d9e6, -18px -18px 30px #ffffff;
}

.bg-ele-one {
  position: absolute;
  width: 191px;
  height: 191px;
  left: -54px;
  top: 823px;
  border-radius: 110px;
}

.bg-ele-two {
  width: 51px;
  height: 51px;
  left: 94px;
  top: 694px;
  border-radius: 50px;
}

.bg-ele-three {
  width: 63px;
  height: 63px;
  left: calc(50% - 63px / 2 - 464.5px);
  top: calc(50% - 63px / 2 - 210px);
  border-radius: 50px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.bg-ele-four {
  position: absolute;
  width: 28px;
  height: 28px;
  right: calc(50% - 350px);
  top: 160px;
  border-radius: 29.5px;
}

.bg-ele-five {
  position: absolute;
  width: 89px;
  height: 89px;
  right: -44px;
  top: -44px;
  box-shadow: inset 18px 18px 30px #d1d9e6, inset -18px -18px 30px #ffffff;
  border-radius: 110px;
}

.messageHeader {
  color: #2a345b;
  font-size: 60px;
  font-family: "Poppings Semibold";
  letter-spacing: 0px;
  line-height: 61px;
}

.link {
  color: #8b81ff;
  text-decoration: none;
}

/* INTRO PAGE */

.introContainer {
  height: 100%;
  max-width: 600px;
  display: flex;
  z-index: 1;
  padding: 50px 30px 5px;
  color: #2a345b;
}

.introHeader {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto 0;
}

.introSubheader {
  font-size: 20px;
  width: 100%;
  margin-top: 30px;
  font-weight: 600;
}

.dataSecureText {
  margin-bottom: 30px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
}
.inputLabel {
  align-self: flex-start;
  color: #2a345b;
  font-size: 12px;
  font-weight: 400px;
  margin-bottom: 2px;
  padding-left: 47px;
  z-index: 1;
}

.checkoutInput {
  box-sizing: border-box;
  border-radius: 25px;
  border: 5px solid #f0f9fb;
  box-shadow: inset -10px -10px 30px #ffffff, inset 10px 10px 30px #dbe4e7,
    -10px -10px 30px #ffffff, 10px 10px 30px #dbe4e7;

  background-color: #f0f9fb;
  margin-bottom: 10px;
  margin: 0px 10px 10px 10px;
  color: #2a345b;
  font-size: 14px;
  padding: 14px 32px 13px 32px;
  width: 250px;
  height: 50px;

  /* this fixes box-shadow not showing on ios safari + chrome */
  -webkit-appearance: none;
}

.checkoutInput::placeholder {
  color: #2a345b;
  font-size: 12px;
  font-weight: 400px;
  font-family: "Poppings Regular";
}

input::-webkit-date-and-time-value {
  text-align: left;
}

.introConsents {
  display: flex;
  margin: 30px 0;
  text-align: left;
}

/* MAIN FORM */

.pageContainer {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f9fb;
  font-family: "Poppings Regular";
  overflow: auto;
}

.prevFormCont {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #f0f9fb;
  border-top: none;
  border-bottom: none;
  padding: 30px 20px 5px 20px;
  box-sizing: border-box;
  height: 100%;
}

.orderButton {
  font-family: "Poppings Regular";
  flex-shrink: 0;
  height: 44px;
  font-weight: 600;
  width: fit-content;
  border: none;
  border-radius: 30px;
  background-color: #f0f9fb;
  color: #2a345b;
  font-size: 15px;
  letter-spacing: 0.2px;
  margin: 10px 0 5px;
  padding: 10px 30px;
  cursor: pointer;
  box-shadow: 18px 18px 30px #d1d9e6, -18px -18px 30px #ffffff;
  transition: background-color 0.5s;
}

.orderButton:disabled {
  border: none;
  cursor: not-allowed;
  box-shadow: -18px -18px 30px 0px #ffffff;
  box-shadow: 18px 18px 30px 0px #d1d9e6;
  color: #dbe4e7;

  -webkit-appearance: none;
}

.orderButton:hover {
  color: #8b81ff;
  background-color: #f0f9fb;
  box-shadow: -8px -8px 20px 0px #ffffff inset;
  box-shadow: 8px 8px 20px 0px #d1d9e6 inset;
}

.orderButton:active {
  color: #8b81ff;
  border: none;
  outline: thin dotted #47cdff;
  box-shadow: -8px -8px 20px 0px #ffffff inset;
  box-shadow: 8px 8px 20px 0px #d1d9e6 inset;
}

.orderButton:focus {
  color: #8b81ff;
  box-shadow: 18px 18px 30px #d1d9e6, -18px -18px 30px #ffffff;
}

.checkBox {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid #edf1f4;
  box-shadow: inset 2px 2px 4px #d1d9e6, 6px 6px 10px #d1d9e6,
    -6px -6px 10px #ffffff;
  background-color: #edf1f4;
  color: #2a345b;
  font-size: 14px;
  margin-right: 20px;
  margin-top: -1px;
}

.checkBox:checked {
  background-color: #8b81ff;
}

/* CHECKOUT PAGE */

.checkoutError {
  color: red;
  font-size: 12px;
  text-align: left;
  padding-left: 47px;
  max-width: 210px;
}

/* SUCCESS PAGE */

.successPage {
  padding: 0px;
}

.successContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  font-family: "Poppings Regular";
}

.successContent {
  width: 100%;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: auto;
  text-align: center;
}

.successHeader {
  color: #2a345b;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 0px;
  margin: 40px auto;
}

.successBody {
  font-size: 16px;
  color: #1a1464;
  width: 350px;
  margin: 0 auto;
}

.demographicSubRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.innerSubRow {
  display: flex;
  flex-direction: column;
}

.singleItemRow {
  justify-content: center;
}

.singleItemRow .checkoutInput {
  width: 300px;
}

.demoSelector__control {
  font-family: "Poppings Regular";
  font-size: 12px;
  text-align: left;
}

.selector > .demoSelector__menu {
  top: 135%;
  left: 0;
  position: absolute;
  border-radius: 25px;
  border: 5px solid #f0f9fb;
  background-color: #f0f9fb;
  font-family: "Poppings Regular";
  font-size: 12px;
  box-shadow: inset -10px -10px 30px #ffffff, inset 10px 10px 30px #dbe4e7,
    -10px -10px 30px #ffffff, 10px 10px 30px #dbe4e7;

  -webkit-appearance: none;
}

.selector > .demoSelector__control > .demoSelector__indicators {
  display: none;
}
/* -------------------------------- */

@media screen and (max-width: 800px) {
  .pageContainer {
    height: 100vh;
    width: 100vw;
  }

  .introHeader {
    width: 50%;
    min-width: 300px;
    margin: auto;
    padding: 80px 0;
  }

  .dataSecureText {
    padding: 0 30px;
  }

  .prevFormCont {
    position: relative;
    display: flex;
    justify-content: initial;
    box-sizing: border-box;
    flex-direction: column;
    border-bottom: 1px solid #e1edf0;
  }

  .messageHeader {
    font-size: 55px;
  }

  .introContainer {
    width: 90vw;
    height: calc(100% - 30px);
    justify-content: flex-start;
    padding: 15px 0 0;
    padding-bottom: 160px;
  }

  .introConsents {
    width: 300px;
  }

  .userInputContainer {
    width: 100%;
  }

  .checkoutError {
    max-width: 190px;
  }

  /* Success Page */

  .successContent {
    justify-content: center;
  }

  .successContent {
    padding: 0 50px;
  }

  .successHeader {
    font-size: 30px;
  }

  .successBody {
    text-align: center;
    width: 300px;
  }

  .successRightColumn {
    position: absolute;
    left: 25%;
    top: 0;
    width: 130%;
    height: 100%;
    background-size: contain;
    background-position: 0 10px;
  }

  .demographicSubRow {
    position: relative;
    flex-direction: column;
    justify-content: center;
  }

  .innerSubRow {
    position: relative;;
    width: max-content;
    margin: auto;
  }

  .singleItemRow .checkoutInput {
    width: 250px;
  }

  .longShape {
    width: 100px;
    left: -40px;
    top: 200px;
  }

  .bg-ele-one {
    width: 122px;
    height: 122px;
    left: -41px;
    top: calc(50% - 122px/2 + 450px);

  }

  .bg-ele-two {
    display: none;
  }

  .bg-ele-five {
    display: none;
  }

  .bg-ele-four {
    position: absolute;
    width: 60px;
    height: 60px;
    right: 10px;
    top: 10px;
    border-radius: 50px;
  }
}
